import proxybase from './proxybase';

class guestService {
  getGuest(identification, eventURL, body = {}) {
    return proxybase
      .post(`/${eventURL}/${identification}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getGuests(eventURL, body = {}) {
    return proxybase
      .post(`/${eventURL}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createAttendance({ body = {}, eventURL }) {
    return proxybase
      .post(`/${eventURL}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateGuestItems({ body = {}, eventURL }) {
    return proxybase
      .put(`/${eventURL}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createGuest(body = {}) {
    return proxybase
      .post(`/users/guest`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getAllGuests(body = {}) {
    return proxybase
      .post(`/users/guests`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateGuest(id, body) {
    return proxybase
      .put(`/users/guest/${id}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getCertificate(id, body = {}) {
    return proxybase
      .post(`/get-certificate/${id}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
export default new guestService();
