import React from 'react';
import ReactDOM from 'react-dom';
import 'sweetalert2/src/sweetalert2.scss';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './routes/App';

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: "https://f78067419c304481ac820211dbccccb6@o482482.ingest.sentry.io/5694389",
    integrations: [
      new Integrations.BrowserTracing(),
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  });
}
  
ReactDOM.render(<App />, document.getElementById('App'));
