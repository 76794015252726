import React from 'react';
import { Button } from 'semantic-ui-react';

const AnswersRow = (props) => {
  const { Answer, set_showAnswers } = props;

  const {
    id,
    event_id,
    Guest
  } = Answer;

  const {
    name,
    identification,
    lastName,
    phone,
  } = Guest

  return (
    <tr>
      <td>
        <p style={{ margin: '0' }}>{identification}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{name}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{lastName}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      {props.match.params.event_name == undefined && <td>
        <p style={{ margin: '0' }}>{event_id}</p>
      </td>}
      <td>
        <Button onClick={() => set_showAnswers(id)}>Ver Respuestas</Button>
      </td>
    </tr>
  );
};

export default AnswersRow;
