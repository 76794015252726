import proxybase from './proxybase';

class eventService {
  getAllEvents({ body }) {
    return proxybase
      .post(`/events/getAllEvents`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getEvent(body) {
    return proxybase
      .post(`/events/getEvent`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createEvent(body) {
    return proxybase
      .post(`/events/create`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateEvent(body) {
    return proxybase
      .post(`/events/update`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
export default new eventService();
