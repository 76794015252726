import React from 'react';
import ReactToPrint from 'react-to-print';

const CertificateIMG = props => {
  const { inputs, certificateIMG, sectionStyle, nameStyle, ccStyle } = props;
  let printable = React.createRef();

  const sectionStyles = {
    backgroundSize: 'cover',
    width: '806px',
    position: 'relative',
    margin: '0 auto',
    height: '603px',
    backgroundPosition: 'left top',
    ...(sectionStyle || {})
  };

  const nameStyles = {
    textAlign: 'center',
    fontSize: '25px',
    position: 'absolute',
    top: '286px',
    width: '100%',
    color: 'white',
    ...(nameStyle || {})
  };

  const ccStyles = {
    textAlign: 'center',
    fontSize: '15px',
    position: 'absolute',
    top: '326px',
    width: '100%',
    color: 'white',
    ...(ccStyle || {})
  };
  const printButton = () => (
    <ReactToPrint
      trigger={() => (
        <a
          href="#"
          onClick={e => e.preventDefault()}
          style={{ textDecoration: 'underline' }}
        >
          Imprimir o guardar en pdf
        </a>
      )}
      content={() => printable.current}
    />
  );

  return (
    <div>
      <div style={{ margin: '10px 0' }}>{printButton()}</div>

      <div ref={printable}>
        <section style={sectionStyles}>
          <img src={certificateIMG} height="603px" width="806px" />
          <p style={nameStyles}>{inputs.name}</p>
          <p style={ccStyles}>{inputs.identification}</p>
        </section>
      </div>
    </div>
  );
};
export default CertificateIMG;
