import React from 'react';
import { errorInput } from '../helpers';
import { Input, Button } from 'semantic-ui-react';

const GenerateCertificate = props => {
  const { setStep, inputs, setInputs } = props;
  const [errorInputs, set_errorInputs] = React.useState(0);

  const verifyForm = () => {
    let mandatoryInputs = ['identification'];

    if (props.showName) {
      mandatoryInputs = [...mandatoryInputs, 'name'];
    }

    let errorInputs = {};

    mandatoryInputs.forEach(input => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] }
      });
    });

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const enterReservation = e => {
    e.preventDefault();
    if (verifyForm()) return;
    setStep(1);
  };

  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <>
      <form onSubmit={e => enterReservation(e)} className="Registration__form">
        <h3>{props.title} </h3>
        <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
          Escribe los datos del certificado
        </label>
        {props.showName && (
          <Input
            style={{ width: '33%', margin: '5px' }}
            onChange={handleInputs}
            name={'name'}
            value={inputs.name}
            placeholder="Nombre"
            id="name"
            className={`Registration__input Registration__input-${'name'} ${
              errorInputs['name'] ? 'error' : ''
            }`}
          />
        )}
        <Input
          style={{ width: '33%', margin: '5px' }}
          onChange={handleInputs}
          name={'identification'}
          value={inputs.identification}
          type="number"
          placeholder="Identificación"
          id="identification"
          className={`Registration__input Registration__input-${'identification'} ${
            errorInputs['identification'] ? 'error' : ''
          }`}
        />
        <Button primary onClick={e => enterReservation(e)}>
          Entrar
        </Button>
      </form>
    </>
  );
};

export default GenerateCertificate;
