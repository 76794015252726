import React from "react";
import Header from "./Header";

const Layout = (props) => {
  const { children, event } = props;
  const [styles, set_styles] = React.useState({});

  React.useEffect(() => {
    if (event && event.User && event.User.event_layout) {
      const { bodyBackgroundColor, headerBackgroundColor } = event.User.event_layout;

      let body = { ...(styles.body || {}) };
      let header = { ...(styles.header || {}) };

      if (bodyBackgroundColor) body.backgroundColor = bodyBackgroundColor;
      if (headerBackgroundColor) header.backgroundColor = headerBackgroundColor;

      set_styles({ ...styles, body, header });
    }
  }, [event]);

  return (
    <section className="App" style={styles.body || {}}>
      <Header {...props} styles={styles} />
      <main className="Content__wrapper">{children}</main>
    </section>
  );
};

export default Layout;
