import React from 'react';
import guestService from '../services/guest';
import Cumbre2019CertificatePage from './certificates/cumbre2019certificatePage';
import Layout from '../components/layout/Layout';

const PrintPage = props => {
  const [guestID, set_guestID] = React.useState('');
  const [loadedData, set_loadedData] = React.useState(false);
  const [errorData, set_errorData] = React.useState(null);
  const [GLSAttendant, set_GLSAttendant] = React.useState({ GLs: [] });

  React.useEffect(() => {
    setTimeout(() => {
      set_errorData('');
    }, 3000);
  }, [errorData]);

  function get_certificate(guestID) {
    guestService
      .getCertificate(guestID)
      .then(response => {
        if (response.data.success) {
          set_GLSAttendant(response.data.GLSAttendant);
        } else {
          set_errorData(response.data.message);
          set_loadedData(false);
        }
      })
      .catch(error => {
        set_loadedData(false);
        set_errorData(error.response.data.message);
      });
  }

  function getGLSAttendant(e) {
    e.preventDefault();
    if (!guestID) {
      set_errorData('No puede estar vacío');
      return;
    }
    set_loadedData(true);
    get_certificate(guestID);
  }

  return (
    <>
      {GLSAttendant.identification ? (
        <Cumbre2019CertificatePage GLSAttendant={GLSAttendant} />
      ) : (
        <Layout>
         
        </Layout>
      )}
    </>
  );
};

export default PrintPage;
