import React from 'react';
import Button from '../components/common/Button';
import authService from '../services/auth';

const Login = props => {
  const [inputs, setInputs] = React.useState({});
  const [errorData, set_errorData] = React.useState(null);
  const [loadedData, set_loadedData] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      set_errorData(null);
    }, 3000);
  }, [errorData]);

  React.useEffect(() => {
    if (localStorage.getItem('userToken')) {
      window.location.replace('/');
    }
  }, []);

  function login(e) {
    const { email, password } = inputs;

    if (!email || !password) {
      set_errorData('Todos los campos son obligatorios');
      return;
    }
    set_loadedData(true);
    authService
      .login({ email, password })
      .then(response => {
        if (response.data.success) {
          localStorage.setItem('userToken', response.data.token);
          window.location.replace('/');
        }
      })
      .catch(error => {
        set_errorData(error.response.data.message);
        set_loadedData(false);
      });
  }

  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <form onSubmit={e => login(e)} className="Registration__form">
      <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
        Inicia Sesión
      </label>
      <input
        placeholder="Email"
        type="email"
        name={'email'}
        className="Registration__input Registration__input-id"
        value={inputs.email}
        onChange={handleInputs}
        style={{ width: '33%', margin: '5px' }}
      />

      <input
        style={{ width: '33%', margin: '5px' }}
        onChange={handleInputs}
        name={'password'}
        value={inputs.password}
        placeholder="Contraseña"
        type="password"
        className="Registration__input Registration__input-email"
      />
      {errorData && (
        <label style={{ display: 'block', padding: '20px 0', color: 'red' }}>
          {errorData}
        </label>
      )}
      <Button text="Inicia" disabled={loadedData} onClick={e => login(e)} />
    </form>
  );
};
export default Login;
