import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Header = (props) => {
  const { event, styles, loading } = props;

  const banner = () => {
    if (event && event.User && event.User.event_layout) {
      if (event.User.event_layout.logoURL) 
        return (<img src={event.User.event_layout.logoURL} alt={event.User.name} />)
      if (event.User.name) return event.User.name;
    }
    if (loading) return "Cargando..."
    return "Coaching Teams";
  };

  return (
    <header className="header" id="nav" style={styles.header || {}}>
      <Link to={event && event.User && event.User.event_layout ? "#" : "/"} style={{ color: "white" }}>
        {banner()}
      </Link>
      {!(event && event.User && event.User.event_layout) && (
        <>
          {localStorage.getItem("userToken") ? (
            <Link to={"/logout"} className="logout">
              <span>Salir</span>
            </Link>
          ) : (
            <Link to="/login" className="logout">
              <span>Login</span>
            </Link>
          )}
        </>
      )}
    </header>
  );
};

export default Header;
