const React = require("react");

const clickableStyles = {
  cursor: "pointer",
  color: "gray",
  textDecoration: "underline",
};

const scrollUp = () => {
  try {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } catch {}
};

const surveys = ["Estilo_de_Comunicacion"];

const weekdays = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sabado",
];

const academicStatuses = [
  "Tecnólogo",
  "Profesional",
  "Especialización",
  "2 o mas especializaciones",
  "Maestría",
  "Maestría con 2 o mas especializaciones",
  "Doctorado",
];

const chPerm = (profile, currentUser) => {
  if (currentUser && currentUser.status) {
    return (
      currentUser.status.find((perm) => profile.includes(perm)) ||
      currentUser.status.includes("totalAccess")
    );
  }
  return false;
};

const copytoClipboard = ({ content, Swal }) => {
  try {
    const el = document.createElement("textarea");
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Swal.fire("Copiado!");
  } catch {}
};

function addHours(hours, time = new Date()) {
  let d = new Date(time);
  return d.setTime(d.getTime() + hours * 60 * 60 * 1000);
}

function countAnswers(obj) {
  try {
    let allLetters = [
      ...new Set(Object.values(obj).map((letter) => letter.letter)),
    ];
    let answersGathered = {};
    allLetters.forEach((letter) => {
      answersGathered[letter] = 0;
    });
    Object.values(obj).forEach((answer) => {
      answersGathered[answer.letter] =
        Number(answersGathered[answer.letter]) + Number(answer.grade);
    });

    answersGathered = Object.entries(answersGathered);

    answersGathered = answersGathered.map((ans) => {
      return { letter: ans[0], grade: ans[1] };
    });
    return sortObjects(answersGathered, "grade");
  } catch (error) {
    return [{}];
  }
}

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const sortObjects = (objs, item, order = "desc") => {
  if (order === "asc")
    return objs.sort((a, b) =>
      a[item] > b[item] ? +1 : b[item] > a[item] ? -1 : 0
    );
  return objs.sort((a, b) =>
    a[item] > b[item] ? -1 : b[item] > a[item] ? +1 : 0
  );
};

const errorInput = ({ errorInputs, input = {} }) => {
  if (Boolean(input.value)) {
    errorInputs = { ...errorInputs, [input.name]: false };
  } else {
    errorInputs = { ...errorInputs, [input.name]: true };
  }
  return errorInputs;
};

const deleteForm = async ({ Swal, subtitle = "", func = () => {} }) => {
  const { value: text } = await Swal.fire({
    title: "Eliminar",
    input: "textarea",
    text: subtitle,
    inputPlaceholder: "Escribe la razón por la que vas a eliminar este campo",
    inputAttributes: {
      "aria-label": "Razón de eliminación",
    },
    showCancelButton: true,
  });

  const successAlert = () => Swal.fire("Eliminado exitosamente");

  if (text) func({ text, successAlert });
};

const selectArea = ({
  Select,
  options,
  label,
  name,
  value,
  loading = false,
  errorInputs = {},
  handler,
}) => (
  <Select
    placeholder={label}
    style={{
      display: "block",
      margin: " 7px 0",
    }}
    value={value}
    className={errorInputs[name] ? "error" : ""}
    onChange={handler}
    name={name}
    loading={loading}
    options={options.map((a) => {
      return { key: a, value: a, text: a };
    })}
  />
);

const textArea = ({
  TextArea,
  label,
  name,
  disabled = false,
  value,
  handler,
  loading = false,
  errorInputs = {},
}) => {
  return (
    <div id="transversal">
      <TextArea
        placeholder={label}
        name={name}
        error={errorInputs[name]}
        value={value}
        disabled={disabled}
        loading={loading}
        onChange={handler}
        className={`Registration__input Registration__input-${name} ${
          errorInputs[name] ? "error" : ""
        }`}
      />
    </div>
  );
};

const formSelect = ({
  Select,
  name,
  label,
  value,
  errorInputs = {},
  disabled = false,
  options,
  handler,
}) => (
  <Select
    placeholder={label}
    className={errorInputs[name] ? "error" : ""}
    onChange={handler}
    value={value}
    disabled={disabled}
    name={name}
    options={options.map((a) => {
      return { key: a, value: a, text: a };
    })}
  />
);

const eventSlugnameTrim = (eventSlugname = "") => {
  if (Boolean(eventSlugname) && typeof eventSlugname === "string") {
    try {
      return eventSlugname.replaceAll("_", " ");
    } catch {
      return eventSlugname.replace(/_/gi, " ");
    }
  }
  return "";
};

const formSelectService = ({
  Select,
  name,
  label,
  value,
  options,
  style = {},
  handler,
  errorInputs = {},
}) => (
  <Select
    placeholder={label}
    onChange={handler}
    value={value}
    name={name}
    id={name}
    style={style}
    className={`Registration__input Registration__input-${name} ${
      errorInputs[name] ? "error" : ""
    }`}
    options={options.map((opt) => {
      return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
    })}
  />
);

const formInput = ({
  Input,
  label,
  name,
  disabled = false,
  value,
  handler,
  loading = false,
  errorInputs = {},
  type = "text",
}) => (
  <Input
    placeholder={label}
    name={name}
    error={errorInputs[name]}
    value={value}
    disabled={disabled}
    loading={loading}
    onChange={handler}
    type={type}
    className={`Registration__input Registration__input-${name} ${
      errorInputs[name] ? "error" : ""
    }`}
  />
);

const uniqueElements2Arrays = (array1, array2) =>
  array1.filter(function (obj) {
    return array2.indexOf(obj) == -1;
  });

export {
  chPerm,
  deleteForm,
  errorInput,
  formInput,
  uniqueElements2Arrays,
  sortObjects,
  shuffle,
  selectArea,
  formSelect,
  textArea,
  countAnswers,
  weekdays,
  addHours,
  formSelectService,
  eventSlugnameTrim,
  copytoClipboard,
  clickableStyles,
  scrollUp,
  surveys,
  academicStatuses,
};
