import React from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import authService from "../../services/auth";
import { Input, Button, Modal, Checkbox } from "semantic-ui-react";

const UserModal = (props) => {
  const [loading, set_loading] = React.useState(false);
  const [inputs, setInputs] = React.useState({});
  const { getInfo, set_create, editingInputs } = props;
  const currentUser = authService.getCurrentUser();

  React.useEffect(() => {
    setInputs({ ...editingInputs });
  }, [editingInputs]);

  const editing = editingInputs.id;

  function save({ deleted = false }) {
    set_loading(true);

    if (editing) {
      authService
        .editUser({ ...inputs, deleted })
        .then((response) => {
          if (response.data.success) {
            Swal.fire(response.data.message);
            getInfo();
          }
          set_loading(false);
        })
        .catch((error) => {
          if (error.response) Swal.fire(error.response.data.message);
          set_loading(false);
        });
    } else {
      authService
        .createUser({ ...inputs, user_id: currentUser.id })
        .then((response) => {
          if (response.data.success) {
            getInfo();
            set_create(false)
            Swal.fire(response.data.message);
          }
          set_loading(false);
        })
        .catch((error) => {
          if (error.response) Swal.fire(error.response.data.message);
          set_loading(false);
        });
    }
  }

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <Modal open onClose={() => set_create(false)}>
      <Modal.Header>{editing ? "Editar" : "Crear"} usuario</Modal.Header>
      <Modal.Content>
        <Input
          placeholder="Nombre del usuario"
          id="name"
          disabled={loading}
          name={"name"}
          className={`Registration__input Registration__input-${"name"} ${""}`}
          value={inputs.name}
          onChange={handleInputs}
          style={{ width: "33%", margin: "5px" }}
        />
        <Input
          placeholder="Email del usuario"
          id="email"
          disabled={loading}
          name={"email"}
          className={`Registration__input Registration__input-${"email"} ${""}`}
          value={inputs.email}
          onChange={handleInputs}
          style={{ width: "33%", margin: "5px" }}
        />
        <Input
          placeholder={`${editing ? "Cambiar" : "Nueva"} contraseña`}
          id="password"
          type="password"
          disabled={loading}
          name={"password"}
          className={`Registration__input Registration__input-${"password"} ${""}`}
          value={inputs.password}
          onChange={handleInputs}
          style={{ width: "33%", margin: "5px" }}
        />
      </Modal.Content>
      <Modal.Actions>
        {editing && (
          <Button
            loading={loading}
            onClick={() => {
              Swal.fire({
                title: "¿Seguro que desea eliminar este usuario?",
                showCancelButton: true,
                confirmButtonText: "Si, Eliminar",
                cancelButtonText: "Volver",
              }).then((result) => {
                if (result.value) {
                  save({ deleted: true });
                }
              });
            }}
          >
            Eliminar usuario
          </Button>
        )}
        <Button
          secondary
          loading={loading}
          disabled={(!inputs.password && !editing) || !inputs.name || !inputs.email}
          onClick={() => save({})}
        >
          {editing ? "Editar" : "Crear"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

UserModal.defaultProps = {
  editingInputs: {},
};

export default UserModal;
