import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { copytoClipboard, clickableStyles } from "../../helpers";
import EventModal from "../../../containers/events/EventModal";
import Swal from "sweetalert2";

const EventRow = (props) => {
  const { event, getInfo, allSurveys } = props;
  const [editing, set_editing] = React.useState(false);

  let { participants, name, approved, surveys } = event;

  return (
    <tr>
      {editing && (
        <EventModal
          getInfo={getInfo}
          allSurveys={allSurveys} 
          match={props.match}
          set_create={set_editing}
          match={props.match}
          editingInputs={event}
        />
      )}
      <td>
        <p
          style={{ margin: "0", ...clickableStyles }}
          onClick={() => set_editing(true)}
        >
          {name.replace(/-/g, " ")}
        </p>
      </td>
      <td>
        <p style={{ margin: "0" }}>{participants}</p>
      </td>
      <td>
        <p style={{ margin: "0" }}>{approved ? "Sí" : "No"}</p>
      </td>
      <td>
        {approved ? (
          <Modal closeIcon trigger={<Button size='tiny'>Enlaces</Button>}>
            <Modal.Header>Enlaces de las encuestas del evento</Modal.Header>
            <Modal.Content>
              <p style={{ padding: "10px 0 15px 0", fontSize: "16px" }}>
                Se pueden acceder en los siguientes enlaces:{" "}</p>
                {surveys.map(survey=>
                  <p style={{ padding: "10px 0 15px 0", fontSize: "16px" }}>
                    <strong>{survey.replace(/_/g,' ')}: </strong>
                    <span
                      style={{ margin: "0", ...clickableStyles }}
                      onClick={() =>
                        copytoClipboard({
                          content: `${window.location.hostname}/encuesta/${survey}/${name}`,
                          Swal,
                        })
                      }
                    >{`${window.location.hostname}/encuesta/${survey}/${name}`}</span><br/>
                  </p>
                )}
              </Modal.Content>
          </Modal>
        ) : (
          <p>Sin aprobar</p>
        )}
      </td>
      {/*<td>
        <p style={{ margin: '0' }}>{event_date}</p>
      </td>*/}
      <td>
        <Button
          onClick={() => props.history.push(`/encuestas/${name}`)}
        >
          Ver
        </Button>
      </td>
    </tr>
  );
};

export default EventRow;
