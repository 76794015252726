import React from 'react';
import cumbre2019 from '../../assets/static/certificate.jpg';
import GenerateCertificate from '../../components/certificate/gencertificate';
import CertificateIMG from '../../components/certificate/certificateIMG';

const Cumbre2019CertificatePage = props => {
  const [step, setStep] = React.useState(0);
  const [inputs, setInputs] = React.useState({});

  if (step === 0) {
    return (
      <GenerateCertificate
        setStep={setStep}
        title={'Generar Certificado GLS 2019'}
        inputs={inputs}
        setInputs={setInputs}
        showName
      />
    );
  } else if (step === 1) {
    return <CertificateIMG inputs={inputs} certificateIMG={cumbre2019} />;
  }
};
export default Cumbre2019CertificatePage;
