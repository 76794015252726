import React from 'react';
import { Link } from 'react-router-dom';

const PublicPage = () => {
  return (
    <>
      <section className="Home">
        <div className="Home__content">
          <h1 className="Home__title">Bienvenido</h1>
          <h2>Coaching Teams</h2>
          <p className="Home__intro">
            <strong style={{ display: 'block', padding: '20px 0 0 0' }}>
              Por favor usa el enlace que te dio tu coach.
            </strong>
          </p>
        </div>
        <div className="Home__buttons"></div>
        <Link to="/login" className="Home__link">
          Acceso a coaches
        </Link>
      </section>
    </>
  );
};

export default PublicPage;
