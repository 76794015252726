import React from 'react';
import AnswersRow from '../../components/common/adminTable/AnswersRow';
import { Button, Input, Select } from 'semantic-ui-react';
import ConditionalAnswers from '../../components/surveys/ConditionalAnswers';
import ReportButton from './ReportButton';

const AnswersTable = (props) => {
  const {
    showAnswers,
    getInfo,
    loaded,
    endPage,
    allAnswers,
    handleInputs,
    inputs,
    set_showAnswers,
    allTutors,
    loadMore,
    allSurveys,
  } = props;

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      getInfo();
    }
  };

  React.useEffect(() => {
    if (inputs.survey_name) getInfo();
  }, [inputs.survey_name]);

  const selectHandle = (a, { value, name }) => handleInputs({ target: { value, name } });

  return (
    <>
      {!showAnswers && (
        <div className="AdminTable__content">
          <Button primary onClick={() => props.history.goBack()}>
            Atrás
          </Button>
          <Button primary onClick={() => getInfo()}>
            Buscar
          </Button>
          <div style={{ padding: '20px 0' }}>
            {props.match.params.event_name ? (
              <h1>Resultados del evento {props.match.params.event_name.replace(/-/g, ' ')}</h1>
            ) : (
              <h1>Resultados de todos los eventos</h1>
            )}
            <h2 style={{ display: 'inline-block', padding: '0 20px 0 0' }}>
              <small>Encuesta: </small>
            </h2>
            <Select
              placeholder="Encuestas"
              onChange={selectHandle}
              value={inputs.survey_name /*|| allSurveys[0]*/}
              name={'survey_name'}
              options={allSurveys.map((a) => {
                return { key: a, value: a, text: a.replace(/_/g, ' ') };
              })}
            />
          </div>
          <div>
            <p style={{ margin: 0 }}>Buscar</p>
            <Input
              loading={!loaded}
              name={'name'}
              onChange={handleInputs}
              value={inputs.name}
              className={'name'}
              style={{ margin: '10px 0' }}
              onKeyPress={onEnter}
              placeholder="Buscar por nombre"
            />
          </div>
          <div>
            <ReportButton
              URL={`/events/report?event_id=${props.event.id}`}
              fileName={`reporte_de_participantes_evento_${props.match.params.event_name}.xlsx`}
              event="Descargar informe participantes"
            />
            <ReportButton
              URL={`/survey/ResultsReport?event_id=${props.event.id}`}
              fileName={`reporte_de_respuestas_evento_${props.match.params.event_name}.xlsx`}
              event="Descargar informe respuestas"
            />
          </div>
        </div>
      )}
      <div>
        {!showAnswers && <p>Total en pantalla: {allAnswers.length}</p>}
        <table>
          {!showAnswers && (
            <thead>
              <tr>
                <th>Cedula</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Celular</th>
                {props.match.params.event_name == undefined && <th>Id Evento</th>}
                <th>Ver</th>
              </tr>
            </thead>
          )}
          <tbody>
            {allAnswers.map((Answer, i) => (
              <>
                {showAnswers === Answer.id && (
                  <ConditionalAnswers
                    match={props.match}
                    Answer={Answer}
                    surveyName={inputs.survey_name}
                    set_showAnswers={set_showAnswers}
                  />
                )}
                {!showAnswers && (
                  <AnswersRow
                    Answer={Answer}
                    allTutors={allTutors}
                    match={props.match}
                    set_showAnswers={set_showAnswers}
                  />
                )}
              </>
            ))}
          </tbody>
        </table>
        {!showAnswers && (
          <div style={{ textAlign: 'center', margin: '20px 0 ' }}>
            <Button disabled={endPage || !loaded} onClick={() => loadMore()}>
              {loaded ? 'Cargar más' : 'Cargando...'}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default AnswersTable;
