import React from "react";
import { countAnswers, scrollUp } from "../../helpers";
import { DISC } from "./DISCInterpretations";
import { Button } from "semantic-ui-react";
import moment from "moment";
import ReactToPrint from "react-to-print";

const DISCUserAnswers = (props) => {
  const { answer, set_showAnswers } = props;
  const [DISCAnswers, set_DISCAnswers] = React.useState(null);
  const [isPrinting, setIsPrinting] = React.useState(false);

  let printable = React.createRef();
  
  React.useEffect(() => {
    set_DISCAnswers(countAnswers(answer.answers));
  }, [answer]);

  const discLetter = (letter) => {
    if (letter === "d") return `D - Determinante`;
    if (letter === "i") return `I - Influyente`;
    if (letter === "s") return `S - Sereno`;
    if (letter === "c") return `C - Concienzudo`;
    return "-";
  };

  React.useEffect(() => {
    scrollUp();
  }, []);

  const printButton = () => (
    <ReactToPrint
      trigger={() => (
        <a href="#" onClick={(e) => e.preventDefault()}>
          Imprimir a pdf los resultados de {answer.name} {answer.lastName}
        </a>
      )}
      content={() => printable.current}
    />
  );

  const dischierarchy = () => {
    try {
      return (
        <p className="userdata">
          Puntajes: <strong>{DISCAnswers[0].letter.toUpperCase()}:</strong>{" "}
          {DISCAnswers[0].grade} -{" "}
          <strong>{DISCAnswers[1].letter.toUpperCase()}:</strong>{" "}
          {DISCAnswers[1].grade} -{" "}
          <strong>{DISCAnswers[2].letter.toUpperCase()}:</strong>{" "}
          {DISCAnswers[2].grade} -{" "}
          <strong>{DISCAnswers[3].letter.toUpperCase()}:</strong>{" "}
          {DISCAnswers[3].grade}
        </p>
      );
    } catch {}
    return <p></p>;
  };

  return (
    <div className="results">
      {localStorage.getItem("userToken") && (
        <Button
          primary
          compact
          onClick={() => set_showAnswers(null)}
          style={{ display: "block", margin: "10px 0" }}
        >
          Ir Atrás
        </Button>
      )}
      <div
        onClick={() => {
          setIsPrinting(true);
          setTimeout(() => {
            setIsPrinting(false);
          }, 1000);
        }}
      >
        {printButton()}
      </div>
      <div
        ref={printable}
        className={`infocover ${isPrinting ? "printable" : ""}`}
      >
        <div className="info">
          <p className="userdata">
            Encuesta de{" "}
            <strong>
              {answer.Guest.name} {answer.Guest.lastName}
            </strong>
          </p>
          <p className="userdata">
            Genero: <strong>{answer.Guest.gender}</strong>
          </p>
          <p className="userdata">
            Telefono: <strong>{answer.Guest.phone}</strong>
          </p>
          <p className="userdata">
            Identificación: <strong>{answer.Guest.identification}</strong>
          </p>
          <p className="userdata">
            Email: <strong>{answer.Guest.email}</strong>
          </p>
          {DISCAnswers && dischierarchy()}

          <p className="userdata">
            Fecha de la encuesta:{" "}
            <strong>
              {moment
                .tz(answer.createdAt, "America/Bogota")
                .format("DD-MM-YYYY")}
            </strong>
          </p>
        </div>

        {/*<FormforTutors
        inputs={answer.SurveyAnswers[0]}
      />*/}
      
        <h1 style={{ padding: "20px 0 0 0" }}>Estilo de Comunicacion</h1>
        {DISCAnswers && Object.keys(DISCAnswers).length && (
          <div className="DISC">
            <h2>La forma como te asocias con las personas </h2>
            <h3>{discLetter(DISCAnswers[0].letter)}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: DISC.find((a) => a.letter[0] === DISCAnswers[0].letter)
                  .text,
              }}
            />
            <h3 className="first">
              {DISCAnswers[0].letter}/{DISCAnswers[1].letter}
            </h3>
            <h2 className="second">
              {discLetter(DISCAnswers[0].letter)} /{" "}
              {discLetter(DISCAnswers[1].letter)}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: DISC.find(
                  (a) =>
                    a.letter[0] === DISCAnswers[0].letter &&
                    a.letter[1] === DISCAnswers[1].letter
                ).text,
              }}
            />
            <p style={{padding: "35px 0 0 0"}}>
              <small>
                Tanto esta encuesta, tanto como los resultados son
                propiedad del coach o de la organización que la crearon;
                coachingteams.co y el coach aqui referenciado se reservan los
                derechos del autor.
              </small>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default DISCUserAnswers;
