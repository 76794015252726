import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import surveyService from '../../services/survey';
import reportService from '../../services/reportService';
import AnswersTable from './AnswersTable';

const AllAnswersTable = props => {
  const [allAnswers, set_allAnswers] = React.useState([]);
  const [event, set_event] = React.useState({});
  const [loaded, set_loaded] = React.useState(null);
  const [showAnswers, set_showAnswers] = React.useState(null);
  const [inputs, setInputs] = React.useState({});
  const [endPage, set_endPage] = React.useState(false);
  const [currentPage, set_currentPage] = React.useState(1);
  const [allSurveys, set_allSurveys] = React.useState([]);

  React.useEffect(() => {
    if (props.match.params.event_name !== undefined) getInfo();
    if (props.match.params.event_name === undefined) getSurveys()
  }, []);

  function getAttendantInfo() {
    set_loaded(false);

    let filters = {};
    Object.entries(inputs)
      .filter(input => input[1] != null)
      .forEach(element => {
        filters[element[0]] = element[1];
      });

    let body = { filter: { ...filters }, currentPage };
    let URL = `/events/eventsP1C3Report`;
    let fileName = `InformeCrecerDiscQuestionLayout.xlsx`;

    reportService
      .getReport({ URL, fileName, body })
      .then(response => {
        set_loaded(true);
      })
      .catch(error => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(true);
      });
  }

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  React.useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  function getInfo(page = false) {
    set_loaded(false);
    set_endPage(false);

    let body = { currentPage, name: inputs.name, event_name: props.match.params.event_name };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    surveyService
      .getAllDISCs({ body, survey_name: inputs.survey_name })
      .then(response => {
        set_loaded(true);
        if (response.data.success) {
          if (response.data.event) {
            set_event(response.data.event)
            set_allSurveys(response.data.event.surveys);
            if (!inputs.survey_name)
              setInputs({...inputs, survey_name: response.data.event.surveys[0] })
          }
          if (page && body.currentPage > 1) {
            set_allAnswers([...allAnswers, ...response.data.Answers]);
          } else {
            set_allAnswers(response.data.Answers);
          }
          if (response.data.Answers.length === 0) {
            set_endPage(true);
          }
        } else {
          Swal.fire(response.data.message);
        }
      })
      .catch(error => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(true);
      });
  }

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const handleInputs = e => {
    let name = e.target.name;
    let value = e.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  function getSurveys() {
    surveyService
      .getSurveys({})
      .then((response) => {
        if (response.data.success && response.data.surveys) {
          set_allSurveys(response.data.surveys.map(s=>s.name));
          setInputs({...inputs, survey_name: response.data.surveys[0].name })
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
      });
  }

  return (
    <section className="AdminTable">
      <AnswersTable
        loadMore={loadMore}
        event={event}
        endPage={endPage}
        allSurveys={allSurveys}
        match={props.match}
        getAttendantInfo={getAttendantInfo}
        showAnswers={showAnswers}
        getInfo={getInfo}
        handleInputs={handleInputs}
        inputs={inputs}
        selectHandle={selectHandle}
        loaded={loaded}
        allAnswers={allAnswers}
        set_showAnswers={set_showAnswers}
        {...props}
      />
    </section>
  );
};

export default AllAnswersTable;
