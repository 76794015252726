import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Form from "../../../components/form/Form";
import { errorInput, formInput, scrollUp } from "../../../components/helpers";
import { Button, Input } from "semantic-ui-react";

const SurveyFillUserInfo = (props) => {
  const { survey, errorInputs, set_errorInputs, set_student, sendAnswers, student, set_step } = props

  const verifyStepOne = () => {
    scrollUp();
    let mandatoryInputs = [
      "name",
      "lastName",
      "identification",
      "phone",
      "email",
      "birthdate",
    ];

    if (window.location.hostname !== "localhost") {
      mandatoryInputs = [...mandatoryInputs, "captcha"];
    }

    if (survey.extraInputs) {
      survey.extraInputs.map(input => {
        mandatoryInputs = [...mandatoryInputs, input.name]
      })
    }

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: student[input] },
      });
    });

    if (student["identification"] && student["identification"].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: "identification",
          value: student["identification"].length >= 5,
        },
      });
    }

    if (student["phone"] && student["phone"].length <= 10) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: "phone",
          value: student["phone"].length >= 10,
        },
      });
    }

    if (window.location.hostname !== "localhost") {
      if (student["captcha"] && student["captcha"] != "null") {
        errorInputs = errorInput({
          errorInputs,
          input: {
            name: "captcha",
            value: student["captcha"].length >= 10,
          },
        });
      }
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_student({ ...student, [name]: value });
  };

  function captchaOnChange(value) {
    set_student({ ...student, captcha: value.split(":").pop() });
  }

  const moreInfo = () => {
    if (survey.extraInputs)
      return survey.extraInputs.map(input => {
        return formInput({
          Input,
          handler: handleInputs,
          label: input.label.replace(/_/g, " "),
          name: input.name,
          value: student[input.name],
          errorInputs,
          type: input.type
        })
      })
  }

  return (
    <div style={{ textAlign: "center" }}>
      <h3>{survey.description && survey.description.personalInfo}</h3>
      {Object.values(errorInputs).includes(true) && (
      <p style={{ color: "red" }}>
          Por favor verifica los campos que faltan por llenar.
      </p>
      )}
      <form className="Registration__form">
        <Form
          Attendant={student}
          showAcademicStatus
          handleInputs={handleInputs}
          errorInputs={errorInputs}
          showBirthdate
          moreInfo={moreInfo}
        />
      </form>
      {errorInputs.captcha && (
        <p style={{ color: "red" }}>Haz clic en el captcha</p>
      )}
      <div
        style={{
          width: "min-content",
          margin: "auto",
          border: errorInputs.captcha ? "3px solid red" : "",
        }}
        className={`captcha ${errorInputs.captcha ? "error" : ""}`}
      >
      <ReCAPTCHA
        style={{ margin: "auto" }}
        sitekey={process.env.REACT_APP_RECAPTCHA}
        onChange={captchaOnChange}
      />
      </div>
      <Button
        primary
        onClick={() => (verifyStepOne() ? set_step(1) : sendAnswers(2))}
      >
        Continuar con la primera parte
      </Button>
    </div>
  )
}
export default SurveyFillUserInfo