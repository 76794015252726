import React from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import eventService from "../../services/events";
import authService from "../../services/auth";
import { Input, Button, Modal, Checkbox, Select } from "semantic-ui-react";
import {
  clickableStyles,
  copytoClipboard,
  chPerm,
} from "../../components/helpers";

const EventModal = (props) => {
  const [loading, set_loading] = React.useState(false);
  const [inputs, setInputs] = React.useState({surveys: []});
  const { getInfo, set_create, editingInputs, allSurveys } = props;
  const currentUser = authService.getCurrentUser();

  React.useEffect(() => {
    setInputs({ ...inputs, ...editingInputs });
  }, [editingInputs]);

  const editing = editingInputs.id;

  function save({ deleted = false }) {
    set_loading(true);

    if (editing) {
      eventService
        .updateEvent({ ...inputs, deleted })
        .then((response) => {
          if (response.data.success) {
            Swal.fire(response.data.message);
            getInfo();
          }
          set_loading(false);
        })
        .catch((error) => {
          if (error.response) Swal.fire(error.response.data.message);
          set_loading(false);
        });
    } else {
      eventService
        .createEvent({ ...inputs, user_id: currentUser.id })
        .then((response) => {
          if (response.data.success) {
            getInfo();
            Swal.fire(response.data.message);
          }
          set_loading(false);
        })
        .catch((error) => {
          if (error.response) Swal.fire(error.response.data.message);
          set_loading(false);
        });
    }
  }

  const handleCheckers = (name) =>
    setInputs({ ...inputs, [name]: !inputs[name] });

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const selectHandle = (a, { value, name }) =>
    handleInputs({ target: { value, name } });

  return (
    <Modal open onClose={() => set_create(false)}>
      <Modal.Header>{editing ? "Editar" : "Crear"} evento</Modal.Header>
      <Modal.Content>
        <Input
          placeholder="Nombre del evento"
          id="name"
          disabled={loading || editing}
          name={"name"}
          className={`Registration__input Registration__input-${"name"} ${""}`}
          value={inputs.name}
          onChange={handleInputs}
          style={{ width: "33%", margin: "5px" }}
        />
        <Input
          style={{ width: "33%", margin: "5px" }}
          onChange={handleInputs}
          type="number"
          disabled={loading || (inputs.approved && editing)}
          name={"participants"}
          value={inputs.participants}
          placeholder="Numero de participantes"
          id="participants"
          className={`Registration__input Registration__input-${"participants"} ${""}`}
        />
        {props.match.params.event_name == undefined &&
          chPerm(["approver"], currentUser) && (
            <div>
              <Checkbox
                label={`${inputs[`approved`] ? 'Autorizado' : 'Sin Autorizar'}`}
                name="approved"
                checked={inputs[`approved`]}
                toggle
                onClick={() => handleCheckers("approved")}
              />
            </div>
          )}
        <div>
          <Checkbox
            label={`Encuestas ${inputs[`allowtofill`] ? 'abiertas' : 'cerradas'}`}
            name="allowtofill"
            checked={inputs[`allowtofill`]}
            toggle
            onClick={() => handleCheckers("allowtofill")}
          />
        </div>
        <div>
          <Checkbox
            label={`Los usuarios ${
              !inputs[`user_view`] ? "no" : ""
            } pueden ver los resultados`}
            name="user_view"
            checked={inputs[`user_view`]}
            toggle
            onClick={() => handleCheckers("user_view")}
          />
          {inputs[`user_view`] &&
            (editing ? (<div style={{display:'block'}}>
              <Modal closeIcon trigger={<Button size='tiny'>Ver visualización por encuesta</Button>}>
                <Modal.Header>Visualizacion de encuestas</Modal.Header>
                <Modal.Content>
                  <p style={{ padding: "10px 0 15px 0", fontSize: "16px" }}>
                    Se pueden visualizar en:{" "}</p>
                    {inputs.surveys.map(survey=>
                      <p style={{ padding: "10px 0 15px 0", fontSize: "16px" }}>
                        <strong>{survey.replace(/_/g,' ')}: </strong>
                        <span
                          style={clickableStyles}
                          onClick={() =>
                            copytoClipboard({
                              content: `${window.location.hostname}/resultados/${survey}/${inputs.name}`,
                              Swal,
                            })
                          }
                        >{`${window.location.hostname}/resultados/${survey}/${inputs.name}`}</span><br/>
                        </p>
                      )}
                  </Modal.Content>
              </Modal>
                <p>Los usuarios al finalizarla, tambien se les permitirá ver los resultados.</p>
              </div>
            ) : (
              <p style={{ padding: "10px 0 15px 0", fontSize: "14px" }}>
                Despues de crear, da clic en el nombre del evento para obtener el link de
                consulta posterior, también a los usuarios al final de esta se les permitirá
                ver los resultados.
              </p>
            ))}
        </div>
        <div style={{padding: '20px 0 0 0'}}>
          <label htmlFor="surveys">
            Encuestas de tu evento
          </label>
          <Select
            placeholder={`Selecciona las encuestas que se usarán en tu evento`}
            onChange={selectHandle}
            value={inputs.surveys || ''}
            name={'surveys'}
            disabled={inputs.approved && editing}
            id={'surveys'}
            fluid
            multiple
            selection
            options={allSurveys.map(opt => {
              return { key: opt.id, value: opt.name, text: opt.name.replace(/_/g, ' ') };
            })}
          />
          {(!inputs[`approved`] && editing) && <p style={{padding: "15px 0 0 0", fontSize: "14px"}}>Pendiente de autorización</p>}
          {(!inputs[`approved`] && !editing) && <p style={{padding: "15px 0 0 0", fontSize: "14px"}}>Recuerda que este evento debe ser autorizado despues de creado</p>}
        </div>
      </Modal.Content>
      <Modal.Actions>
        {editing && (
          <Button
            loading={loading}
            onClick={() => {
              Swal.fire({
                title: "¿Seguro que desea eliminar este evento?",
                showCancelButton: true,
                confirmButtonText: "Si, Eliminar",
                cancelButtonText: "Volver",
              }).then((result) => {
                if (result.value) {
                  save({ deleted: true });
                }
              });
            }}
          >
            Eliminar evento
          </Button>
        )}
        <Button
          secondary
          loading={loading}
          disabled={!inputs.participants || !inputs.name || inputs.surveys.length === 0}
          onClick={() => save({})}
        >
          {editing ? "Editar" : "Crear"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

EventModal.defaultProps = {
  editingInputs: {},
};

export default EventModal;
