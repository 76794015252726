import React from "react";
import { Button, Modal, Input } from "semantic-ui-react";
import authService from "../../services/auth";
import { SwatchesPicker } from "react-color";
import Swal from "sweetalert2";

const UserConfigurations = () => {
  const [open, set_open] = React.useState(false);
  const [loading, set_loading] = React.useState(false);
  const [inputs, set_inputs] = React.useState({});

  const currentUser = authService.getCurrentUser();

  React.useEffect(() => {
    const { event_layout } = currentUser;
    let layoutInputs = {};

    try {
      if (event_layout) {
        if (event_layout.bodyBackgroundColor)
          layoutInputs.body = event_layout.bodyBackgroundColor;
        if (event_layout.headerBackgroundColor)
          layoutInputs.header = event_layout.headerBackgroundColor;
        if (event_layout.logoURL) layoutInputs.logoURL = event_layout.logoURL;
      }
    } catch {}

    set_inputs(layoutInputs);
  }, []);

  const openModal = (e) => {
    e.preventDefault();
    set_open(true);
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_inputs({ ...inputs, [name]: value });
  };

  const save = () => {
    set_loading(true);

    const body = {
      event_layout: {
        bodyBackgroundColor: inputs.body.hex,
        headerBackgroundColor: inputs.header.hex,
        logoURL: inputs.logoURL,
      },
      password: inputs.password,
      email: currentUser.email,
    };

    authService
      .editUser(body)
      .then((response) => {
        if (response.data.success) {
          Swal.fire(response.data.message);
        }
        if (response.data.token) {
          localStorage.setItem('userToken', response.data.token);
        }
        set_loading(false);
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  };

  const sample = {
    main: {
      width: "36%",
      height: "50px",
      margin: "0 0 20px 0",
      border: "1px solid gray"
    },
    header: {
      height: "15px",
      width: "100%",
      backgroundColor: typeof inputs.header === "object" && inputs.header ? inputs.header.hex : inputs.header
    },
    body: {
      height: "33px",
      width: "100%",
      backgroundColor: typeof inputs.body === "object" && inputs.body ? inputs.body.hex : inputs.body
    }
  }
  
  return (
    <Modal
      className="configurations"
      open={open}
      onClose={() => set_open(false)}
      centered={false}
      trigger={
        <a href="#" onClick={(e) => openModal(e)} className="Home__link">
          Configuración
        </a>
      }
    >
      <Modal.Header>Configuraciones de tu cuenta</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <h3>Tu Cuenta</h3>
          <p>Cambia tu contraseña</p>
          <Input
            placeholder={`Cambiar contraseña`}
            id="password"
            type="password"
            name={"password"}
            className={`Registration__input Registration__input-${"password"} ${""}`}
            value={inputs.password}
            onChange={handleInputs}
            style={{ width: "33%", margin: "5px" }}
          />
          <h3>Colores de tus encuestas</h3>
          <p>Cabecera</p>
          <SwatchesPicker
            color={inputs.header}
            height="150px"
            onChangeComplete={(e) => set_inputs({ ...inputs, header: e })}
          />
          <p>Cuerpo</p>
          <SwatchesPicker
            height="150px"
            color={inputs.body}
            onChangeComplete={(e) => set_inputs({ ...inputs, body: e })}
          />
          <p><small>Como se vé</small></p>
          <div style={sample.main}>
            <div style={sample.header}></div>
            <div style={sample.body}></div>
          </div>
          <h3>Logo en la cabecera de tus eventos</h3>
          <Input
            name={"logoURL"}
            onChange={handleInputs}
            value={inputs.logoURL}
            placeholder="Escribe la url de tu logo"
          />
          <p>
            <small>Si no pones un logo se mostrará tu nombre</small>
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => set_open(false)} disabled={loading}>
          Cerrar
        </Button>
        <Button primary onClick={() => save()} disabled={loading}>
          Guardar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UserConfigurations;
