const DISC = [
    {
        letter: ["d"], text: `
    <p style="font-size:26px; color:red">Rojo</p>
    <p style="margin: 0"><b>Cómo son:</b></p>
    <p>Directos, pragmáticos y exigentes, Lógico, analítico, decidido, evaluador, matemático, calculador, confrontador, organizador de metas, focalizado</p>
    <p style="margin: 0"><b>Fortalezas:</b></p>
    <p>Orientados al resultado, resueltos y vehementes</p>
    <p style="margin: 0"><b>Limitantes:</b></p>
    <p>Poco diplomáticos, confrontantes e impacientes. </p>
    <p style="margin: 0"><b>Para qué son fuertes:</b></p>
    <p>Resultados, negociación y retos. </p>
    <p style="margin: 0"><b>Para qué no son fuertes:</b></p>
    <p>Procesos, conciliación y servicio.</p>
    <br/>
    <p><b>Cualidades</b></p>
    <p>• Directos y decisivos.</p>
    <p>• Son tomadores de riesgos y solucionadores de problemas.</p>
    <p>• Están más preocupados por completar las tareas satisfactoriamente que aquellos que dependen de la aprobación de la gente para realizar sus tareas.</p>
    <p>• Aunque su motivación interna tiende a hacerlos insensibles a los que les rodean no tienen miedo de desafiar el status quo y prosperan cuando se trata de desarrollar nuevas cosas.</p>
    <p>• Necesitan disciplina para sobresalir y responden a la confrontación.</p>
    <p>• El mayor temor de un “D” es sentirse usados o que alguien se aproveche de ellos.</p>
    <p>• Sus debilidades incluyen una aversión a la rutina, una tendencia a sobrepasar la autoridad, una naturaleza argumentativa y el hábito de asumir muchas responsabilidades.</p>
    <p>• Sus fortalezas incluyen dar un alto valor al tiempo y utilizan su pensamiento innovador para realizar tareas difíciles y conquistar desafíos.</p>
    <strong>DESARROLLANDO TU PERSONALIDAD</strong>
    <p><b>Cada personalidad posee fortalezas y es importante entender cómo establecer un balance entre tus habilidades naturales y las de otras personas. La siguiente información te brindará aquellas áreas en las que podrás enfocarte al trabajar en conjunto con otras personas.</b></p>
    <p>Las personalidades con esta letra suelen ser Dominantes.</p>
    <p>Direccionan, orientan procesos, son decisivos, son organizados, desean superar expectativas y hablan con franqueza.</p>
    <p>• Escuchan atentamente a otros.</p>
    <p>• Apoya a otros miembros del grupo.</p>
    <p>• Invierte en relaciones personales.</p>
    <p>• Balance entre tendencias controlantes y dominantes.</p>
    `},
    {
        letter: ["d", "i"], text: `
    <p>• Son personas curiosas; tienen en alto valor terminar sus tareas, no dejan nada a medias, ponen su énfasis en el resultado final y trabajan duro para alcanzar sus metas.</p>
    <p>• Son personas más determinantes que inspiradoras. Sus altas expectativas y estándares para sí mismos y los que les rodean normalmente hace que causen un gran impacto, motivando a otros a seguirlos. </p>
    <p>• Tienen intereses muy determinados y pueden distraerse al asumir demasiados proyectos.</p>
    <p>• A menudo necesitan concentrarse, Priorizar y simplemente hacer todo a un ritmo razonable.</p>
    <p>• Debido a que los “D / I” s prosperan en la actividad y elmovimiento progresivo, les gusta lograr tareas a través de un gran número de personas.</p>
    `},
    {
        letter: ["d", "s"], text: `
    <p>• Conquistadores con una alta capacidad de perseverancia.</p>
    <p>• Son más activos que pasivos, pero poseen una especie de calma, sensibilidad y estabilidad que los convierte en buenos líderes.</p>
    <p>• Parecen estar enfocados en la gente, pero pueden ser dominantes y decisivos cuando se trata de tareas y planificación de proyectos. Se esfuerzan por lograr los objetivos con feroz determinación que con frecuencia proviene de una fuerte motivación interna.</p>
    <p>• Podrían mejorar al aplicar un pensamiento contemplativo, conservador y dedicar más tiempo cultivando relaciones.</p>
    <p></p>`},
    {
        letter: ["d", "c"], text: `
    <p>• Son retadores, pueden ser estudiantes determinados o críticos desafiantes.</p>
    <p>• Estar a cargo es importante para ellos, sin embargo les importa poco lo que piensan los demás, su enfoque radica en el cumplimiento de la tarea.</p>
    <p>• Tienen una gran capacidad de previsión para evaluar todas las vías con el fin encontrar la mejor solución ante una situación. Prefieren trabajar solos.</p>
    <p>• Aunque temen al fracaso y la falta de influencia, están motivados por desafíos y pueden ser a menudo excelentes administradores. Pueden beneficiarse de aprender a relajarse y prestar más atención a las personas.</p>
    `},
    {
        letter: ["i"], text: `
    <p style="font-size:26px; color:orange">Amarillo</p>
    <p style="margin: 0"><b>Cómo son:</b></p>
    <p>Sociables, optimistas y carismáticos. Creativo, Visionario, Innovador, Recursivo, Sintetizador, Arriesgado, Simultaneo, Holístico, Generador de cambio, flexibilidad y adaptación</p>
    <p style="margin: 0"><b>Fortalezas:</b></p>
    <p>Creatividad, motivación y contactos.</p>
    <p style="margin: 0"><b>Limitantes:</b></p>
    <p>Ingenuidad, subjetividad y dispersión. </p>
    <p style="margin: 0"><b>Para qué son fuertes:</b></p>
    <p>Inspiración, comunicación y motivación </p>
    <p style="margin: 0"><b>Para qué no son fuertes:</b></p>
    <p>Detalle, seguimiento y análisis permanente</p>
    <br/>
    <p><b>Cualidades</b></p>
    <p>• Inspirador y entusiasta; optimista, impulsivo y emocional, tienden a resolver creativamente los problemas y son excelentes motivadores.</p>
    <p>• A menudo tienen un gran número de amigos, pero pueden llegar a preocuparse más por la aprobación y la popularidad que con obtener resultados.</p>
    <p>• El mayor temor es el rechazo, pero prosperan cuando se trata de motivar a los demás. Su sentido del humor positivo suele ser de ayuda para negociar conflictos. • Pueden ser desatentos a los detalles y les cuesta escuchar a otros.</p>
    <p>• Pueden ser grandes pacificadores y compañeros eficaces cuando controlan sus sentimientos y minimizan su deseo de entretener y ser el centro de atención.</p>
    <p>• Valoran mucho el tacto humano y la conexión.</p>
    <strong>DESARROLLANDO TU PERSONALIDAD</strong>
    <p><b>Cada personalidad posee fortalezas y es importante entender cómo establecer un balance entre tus habilidades naturales y las de otras personas. La siguiente información te brindará aquellas áreas en las que podrás enfocarte al trabajar en conjunto con otras personas.</b></p>
    <p>Las personalidades con esta letra suelen ser Influyentes, ingeniosos, tolerantes, desean superar expectativas y guían personas. </p>
    <p>Está atento de tareas que deben cumplirse.</p>
    <p>• Tiene balance entre sus emociones, palabras y acciones.</p>
    <p>• Recuerda detalles analizados y hechos.</p>
    <p>• Es paciente con otros cuando es necesario.</p>
    <p>• Escucha atentamente en vez de solo hablar.</p>
    <p>• Toma decisiones cuidadosamente en vez de decisiones impulsivas.</p>`},
    {
        letter: ["i", "d"], text: `
    <p>• Son persuasivos; extrovertidos y enérgicos.</p>
    <p>• Disfrutan estar rodeados de grandes grupos de personas y usan su poder de influencia para lograr el respeto y convencer a la gente a seguir su ejemplo. </p>
    <p>• A veces pueden ser vistos como inquietos y nerviosos, pero esto proviene de su necesidad de ser parte de retos que requieren variedad, libertad y movilidad.</p>
    <p>• Los “I / D” podrían beneficiarse de aprender a mirar antes de cruzar y pasar más tiempo en buscar calma. Tienden a ser líderes inspiradores y saben cómo obtener resultados a través de la gente.</p>
    `},
    {
        letter: ["i", "s"], text: `
    <p>• Consejeros influyentes que aman a la gente.</p>
    <p>• No es una sorpresa que la gente los ame.</p>
    <p>• Viven para complacer, servir y tienden a ser buenos oyentes.</p>
    <p>• Dar una buena apariencia y animar a otros es importante para ellos, tal como cumplir una labor y serobediente.</p>
    <p>• A menudo carecen en el área de organización y pueden preocuparse más por las personas involucradas que por las tareas que se les ha asignado.</p>
    <p>• Sea como centro del escenario o detrás de escena, trabajan con igual eficacia y brillan cuando se trata de influir y ayudar a los demás.</p>
    `},
    {
        letter: ["i", "c"], text: `
    <p>• Inspirador pero cauteloso; son excelentes comunicadores a través de la combinación de la observación y aprecio a la gente.</p>
    <p>• Sobresalen al buscar maneras de mejorar la producción.</p>
    <p>• Tienden a ser impacientes y críticos, pueden ser demasiado persuasivos y demasiado consumidos por el deseo de ganar.</p>
    <p>• Les gusta trabajar en un entorno seguro, y podrían beneficiarse al probar cosas nuevas y preocuparsemenos por lo que otros piensan de ellos.</p>
    <p>• Este tipo de personalidad a menudo posee un don para enseñar; Son generalmente confiables cuando se trata de prestar atención a los detalles y cumplir el trabajo.</p>
    `},
    {
        letter: ["s"], text: `
    <p style="font-size:26px; color:green">Verde</p>
    <p style="margin: 0"><b>Cómo son:</b></p>
    <p>Aceptadores, diplomáticos y conciliadores. Armonizador, conciliación, empático, Intuitivo, expresivo, motivador, espiritual, rítmico, afiliativo</p>
    <p style="margin: 0"><b>Fortalezas:</b></p>
    <p>Pacientes, empáticos y discretos</p>
    <p style="margin: 0"><b>Limitantes:</b></p>
    <p>Indecisos, renuentes al cambio y posesivos.</p>
    <p style="margin: 0"><b>Para qué son fuertes:</b></p>
    <p>Procesos, conciliación y servicio</p>
    <p style="margin: 0"><b>Para qué no son fuertes:</b></p>
    <p>Resultados, negociación y retos</p>
    <br/>
    <p><b>Cualidades</b></p>
    <p>• Estables y reservados.</p>
    <p>• No les gusta el cambio y prosperan en ambientes seguros no amenazantes.</p>
    <p>• A menudo son amables y comprensivos, así como buenos oyentes y trabajadores leales, que están felices de hacer el mismo trabajo de forma consistente.</p>
    <p>• Poseen una increíble capacidad para perdonar, son fiables, confiables y tienden a ser los mejores amigos.</p>
    <p>• Su mayor temor, sin embargo, es la pérdida de seguridad y sus posibles debilidades incluyen naturalmente no sólo resistencia al cambio, pero también dificultad para adaptarse a ella.</p>
    <p>• También pueden ser demasiado sensibles a la crítica y se les dificulta establecer prioridades.</p>
    <p>• Con el fin de evitar sentirse usados, los “S” necesitan aprender a ser más francos y decir “No”.</p>
    <p>• Les gusta evitar ser el centro de atención, pero cuando se les da la oportunidad de ayudar genuinamente a otros toman el liderazgo y se apropian de la situación adecuadamente.</p>
    <p>• Se sienten más valorados cuando realmente han ayudado a alguien.</p>
    <strong>DESARROLLANDO TU PERSONALIDAD</strong>
    <p><b>Cada personalidad posee fortalezas y es importante entender cómo establecer un balance entre tus habilidades naturales y las de otras personas. La siguiente información te brindará aquellas áreas en las que podrás enfocarte al trabajar en conjunto con otras personas.</b></p>
    <p>Las personalidades con esta letra son firmes, analíticos, introvertidos y guían personas.</p>
    <p>• Tomar iniciativa.</p>
    <p>• Incentivar confrontación constructiva.</p>
    <p>• Ser directo en sus intervenciones cuando es necesario.</p>
    <p>• Entender que los cambios pueden ser saludable y estar dispuesto a adoptarlos.</p>
    <p>• Considerar los objetivos generales de su familia o grupo, no solamente los procedimientos específicos.</p>
    `},
    {
        letter: ["s", "d"], text: `
    <p>• Líderes tranquilos con quien se puede contar para cumplir su trabajo.</p>
    <p>• Se desempeñan mejor en grupos pequeños y no disfrutan hablar frente a las multitudes.</p>
    <p>• Aunque pueden ser suaves y duros al mismo tiempo, disfrutan relacionarse con las personas, teniendo cuidado de no dominarlas.</p>
    <p>• Los desafíos los motivan, sobre todo los que requieren aplicar una solución sistemática.</p>
    <p>• Tienden a ser determinados, perseverando a través del tiempo y las luchas.</p>
    <p>• Se benefician de relaciones positivas y cuando otros los animan.</p>
    `},
    {
        letter: ["s", "i"], text: `
    <p>• Consejeros inspiracionales que infunden calidez y sensibilidad.</p>
    <p>• Son tolerantes y tienen una capacidad increíble para perdonar, tienen muchos amigos porque aceptan y representan bien a otros.</p>
    <p>• Su naturaleza social es ser simpático y flexible lo que hace que se incline a ser demasiado tolerante y evite laconfrontación.</p>
    <p>• Se beneficiará al ser más orientado a tareas y prestar más atención a los detalles.</p>
    <p>• Son amables y considerados, incluyen a otros e inspiran a la gente a seguirlos.</p>
    <p>• Las palabras de afirmación ayudan mucho a este tipo de personalidad y con la motivación correcta pueden desempeñar bien el trabajo en equipo.</p>
    `},
    {
        letter: ["s", "c"], text: `
    <p>• Diplomático y constante.</p>
    <p>• Enfocado en los detalles.</p>
    <p>• Estables y contemplativos, Evalúa los hechos y la evidencia para llegar a una conclusión lógica.</p>
    <p>• Son claros y cautelosos a la hora de tomar decisiones, especialmente cuando la decisión involucra a otros.</p>
    <p>• Las posibles debilidades de este perfil incluyen, ser muy sensible e incapaz de manejar las críticas, tienen dificultad para ser conscientes en la forma en que tratan a los demás.</p>
    <p>• Funcionan mejor en proyectos específicos.</p>
    <p>• Pueden ser pacificadores, esto los hace miembros leales de un equipo y un buen amigo.</p>
    `},
    {
        letter: ["c"], text: `
    <p style="font-size:26px; color:blue">Azul</p>
    <p style="margin: 0"><b>Cómo son:</b></p>
    <p>Rigurosos, formales y metódicos. Cauteloso, ordenado, minucioso, secuencial, alta confiabilidad en la realización de tareas, rutinario, procedimental, eficiente, conservador</p>
    <p style="margin: 0"><b>Fortalezas:</b></p>
    <p>Objetivos, realistas y rigurosos.</p>
    <p style="margin: 0"><b>Limitantes:</b></p>
    <p>Pesimistas, meticulosos e incrédulos.</p>
    <p style="margin: 0"><b>Para qué son fuertes:</b></p>
    <p>Detalle, seguimiento y análisis</p>
    <p style="margin: 0"><b>Para qué no son fuertes:</b></p>
    <p>Inspiración, comunicación y motivación</p>
    <br/>
    <p><b>Cualidades</b></p>
    <p>• Obediente y analítico. Poseen un pensamiento cuidadoso y lógico que los impulsan hacia adelante donde la precisión es una prioridad.</p>
    <p>• Tienen altos estándares en cada área de sus vidas y tienen en cuenta todas las perspectivas posibles para la resolución de conflictos.</p>
    <p>• Aunque prosperan al encontrar soluciones, tienden a ignorar los sentimientos de los demás y pueden a menudo ser críticos y en ocasiones caprichosos.</p>
    <p>• Les cuesta expresar sus sentimientos.</p>
    <p>• Pueden ayudar a su equipo con una confrontación de la realidad de una situación cuando no están atascados en detalles mínimos.</p>
    <p>• El mayor temor de este perfil es la crítica y su necesidad de perfección es a menudo una debilidad, como es su tendencia a ceder en medio de un argumento.</p>
    <p>• Son completos en todas las actividades y pueden aportar un elemento concienzudo e igualado al equipo que proporcionará una opinión fundamentada.</p>
    <p>• Valoran tener la razón.</p>
    <strong>DESARROLLANDO TU PERSONALIDAD</strong>
    <p><b>Cada personalidad posee fortalezas y es importante entender cómo establecer un balance entre tus habilidades naturales y las de otras personas. La siguiente información te brindará aquellas áreas en las que podrás enfocarte al trabajar en conjunto con otras personas.</b></p>
    <p>Personalidades con esta letra son obedientes, competentes, orientados en tareas e introvertidos.</p>
    <p>• Ser decisivo cuando es necesario.</p>
    <p>• Cultiva relaciones personales.</p>
    <p>• Estar abierto a las ideas y métodos de otros.</p>
    <p>• Hace un balance entre los hechos y las personas.</p>
    <p>• Enfocarse en hacer lo correcto, no solamente en hacer las cosas correctamente.</p>
    <p>• Le entusiasma la idea de ayudar a otros a cumplir sus logros.</p>
    `},
    {
        letter: ["c", "i"], text: `
    <p>• Atento a los detalles.</p>
    <p>• Tienden a impresionar a otros haciendo las cosas bien y estabilizando las situaciones.</p>
    <p>• No son considerados como personas agresivas o manipuladoras.</p>
    <p>• Disfrutan de hacer parte de grandes y pequeños grupos de personas.</p>
    <p>• Aunque funcionan bien con la gente, a veces son demasiado sensibles a lo que otros piensan de ellos y de su trabajo.</p>
    <p>• Podrían beneficiarse al ser más asertivos y proactivos.</p>
    <p>• A menudo tiene una capacidad para discernir el carácter de manera acertada.</p>
    <p>• Confían fácilmente en aquellos que cumplen con sus normas.</p>
    <p>• Se conmueven por la aprobación genuina así como de las explicaciones concisas y lógicas.</p>
    `},
    {
        letter: ["c", "s"], text: `
    <p>• Sistemático y estable.</p>
    <p>• Tienden a hacer una cosa a la vez y a hacerlo bien.</p>
    <p>• Son reservados y cautelosos y prefieren trabajar detrás de escena para mantenerse enfocados.</p>
    <p>• Rara vez asumen riesgos o prueban cosas nuevas y naturalmente no les gustan los cambios repentinos en sus ambientes.</p>
    <p>• Perfeccionistas al pie de la letra.</p>
    <p>• Temen a la crítica lo cual asocian con el fracaso.</p>
    <p>• Trabajadores diligentes y su motivación viene de servir a los demás.</p>
    `},
    {
        letter: ["c", "d"], text: `
    <p>• Diseñadores cautelosos y decididos.</p>
    <p>• Están enfocados constantemente en las tareas y están muy conscientes de los problemas.</p>
    <p>• A veces son vistos como insensibles, pero en realidad se preocupan por las personas, solo que les cuesta demostrarlo.</p>
    <p>• A menudo sienten que son los únicos que pueden hacer el trabajo de la manera correcta.</p>
    <p>• Debido a sus habilidades administrativas son capaces de llevar a cabo planes asertivos para el cambio y el mejoramiento.</p>
    <p>• Tienden a tener una apariencia seria y podrían beneficiarse al ser más optimistas y entusiastas.</p>
    <p>• A pesar de su deseo por cumplir con sus tareas deben tomar tiempo en desarrollar relaciones sanas y simplemente amar a la gente.</p>
    `},
]

export { DISC }