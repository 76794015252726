import React from "react";
import { Button } from "semantic-ui-react";
import Swal from "sweetalert2";
import surveyService from "../../services/survey";
import eventService from "../../services/events";
import { scrollUp } from "../../components/helpers";
import Layout from "../../components/layout/Layout";
import QuestionsLayout from "./components/questions";
import {
  addCounter,
  clearTimers,
  setTimers,
} from "../../components/helpers/createTimers";
import SurveyEndingScreen from "./components/surveyEndingScreen";
import SurveyFillUserInfo from "./components/surveyFillUserInfo";
import queryString from "query-string";

let timerTimeout = null;

const SurveyQuestions = (props) => {
  const [student, set_student] = React.useState({});
  const [Answers, set_Answers] = React.useState({});
  const [survey, set_survey] = React.useState({ questions: [] });
  const [step, set_step] = React.useState(null);
  const [loading, set_loading] = React.useState(true);
  const [errorInputs, set_errorInputs] = React.useState({});
  
  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  const loadqueryStudentInfo = (extraInputs = []) => {
    let preStudentInfo = {};

    preStudentInfo = { ...preStudentInfo, identification: parsedQueryString.identification };
    preStudentInfo = { ...preStudentInfo, name: parsedQueryString.name };
    preStudentInfo = { ...preStudentInfo, lastName: parsedQueryString.lastName };
    preStudentInfo = { ...preStudentInfo, phone: parsedQueryString.phone };
    preStudentInfo = { ...preStudentInfo, email: parsedQueryString.email };
    preStudentInfo = { ...preStudentInfo, birthdate: parsedQueryString.birthdate };

    extraInputs.forEach((input) => {
      preStudentInfo = { ...preStudentInfo, [input.name]: parsedQueryString[input.name] };
    });

    return preStudentInfo
  };

  React.useEffect(() => {
    scrollUp();
  }, [step]);

  const sendAnswers = (nextStep) => {
    set_loading(true);

    let aditionalinformation = {};

    if (survey.extraInputs) {
      survey.extraInputs.map((input) => {
        aditionalinformation = {
          ...aditionalinformation,
          [input.name]: student[input.name],
        };
      });
    }

    let body = {
      answers: Answers,
      event_id: props.match.params.event_id.split("-").pop(),
      survey_name: props.match.params.surveyName,
      aditionalinformation,
      ...student,
    };

    if (props.repeating) body = { ...body, repeated: true };

    surveyService
      .DISCForm(body)
      .then((response) => {
        if (response.data.success) {
          set_step(nextStep);
          set_loading(false);
        } else {
          Swal.fire(
            response.data.message ||
              "Hubo un error, por favor vuelve a intentar mas tarde."
          );
          set_loading(false);
          set_step(1);
        }
      })
      .catch((error) => {
        if (error.response)
          Swal.fire(
            error.response.data.message ||
              "Hubo un error, por favor vuelve a intentar mas tarde."
          );
        set_loading(false);
        set_step(1);
      });
  };

  React.useEffect(() => {
    if (step === survey["questions"].length + 2) {
      sendAnswers(survey["questions"].length + 3);
    }

    if (timerTimeout) clearTimeout(timerTimeout);
    set_errorInputs({});

    const index = step - 2;

    clearTimers();

    if (
      index >= 0 &&
      step < survey["questions"].length + 2 &&
      survey["questions"][index].minutes
    ) {
      timerTimeout = setTimeout(() => {
        set_step(step + 1);
      }, survey["questions"][index].minutes * 60 * 1000);
      addCounter();
      setTimers(survey["questions"][index].minutes * 60);
    }
  }, [step]);

  React.useEffect(() => {
    document.title = props.match.params.surveyName.replace(/_/g, " ");
    if (props.match.params.event_id) {
      set_loading(true);

      const body = {
        name: props.match.params.event_id,
        survey: props.match.params.surveyName,
        answer: true,
      };

      eventService
        .getEvent(body)
        .then((response) => {
          if (response.data.success && response.data.event) {
            set_student({ 
              ...student, 
              event: response.data.event, 
              ...loadqueryStudentInfo(response.data?.questions?.extraInputs) 
            });
          }
          if (response.data.success && response.data.approved) {
            set_step(0);
            set_loading(false);
            if (response.data.questions) set_survey(response.data.questions);
          } else {
            if (response.data.message) Swal.fire(response.data.message);
            set_loading(false);
          }
        })
        .catch((error) => {
          Swal.fire("Hubo un error, por favor vuelve a intentar mas tarde.");
          set_loading(false);
        });
    }
  }, []);

  const getArr = (arr) => (Array.isArray(arr) ? arr : []);

  const layout = (index) => {
    const customDescriptions = () => {
      if (survey.description && survey.description.headerDescriptions) {
        return [
          ...getArr(survey.description.headerDescriptions),
          ...getArr(survey.questions[index].blockDescription),
        ].map((headerDescr) => (
          <p
            dangerouslySetInnerHTML={{
              __html: headerDescr,
            }}
            style={{ textAlign: "center", margin: 0 }}
            key={headerDescr}
          />
        ));
      }
    };

    if (
      survey.questions &&
      survey.questions[index].answers &&
      survey.questions[index].answers.length
    ) {
      return (
        <>
          <p>
            Responde las siguientes preguntas donde:{" "}
            {survey.questions[index].answers.map((ans) => (
              <>
                <strong key={ans.num}>{ans.num}</strong> es {ans.answer},{" "}
              </>
            ))}
            responde con cuidado.
          </p>
          {customDescriptions()}
        </>
      );
    }
    return (
      <>
        <p style={{ textAlign: "center", margin: 0 }}>
          Responde las siguientes preguntas.
        </p>
        {customDescriptions()}
      </>
    );
  };

  const renderQuestions = (index) => {
    return (
      <>
        <h3>Parte {index + 1}</h3>
        {layout(index)}
        <QuestionsLayout
          set_Answers={set_Answers}
          Answers={Answers}
          index={index}
          survey={survey}
          set_step={set_step}
          errorInputs={errorInputs}
          set_errorInputs={set_errorInputs}
        />
      </>
    );
  };

  return (
    <Layout loading={loading} event={student.event ? student.event : {}}>
      <div className="Survey">
        <div className="Questions">
          <h1>{props.match.params.surveyName.replace(/_/g, " ")}</h1>
          {step === null && !loading && (
            <p>
              Enlace no valido. <br /> Verifica el enlace que te proporcionaron.
            </p>
          )}
          {loading && <p>Cargando formulario...</p>}
          {step === 0 && (
            <div className="intro">
              {survey.description &&
                survey.description.initial.map((ini) => <p key={ini}>{ini}</p>)}
              <p style={{ margin: 0 }}>
                <strong>Nota importante:</strong> Son{" "}
                {survey["questions"].length} partes de{" "}
                {survey.description && survey.description.kind}, cuando termines
                cada sección, debes presionar el botón azul que está al final de
                la página para continuar a la sección siguiente.
              </p>
              <Button primary onClick={() => set_step(1)}>
                Iniciar {survey.description && survey.description.kind}
              </Button>
            </div>
          )}
          {step === 1 && (
            <SurveyFillUserInfo
              survey={survey}
              errorInputs={errorInputs}
              set_errorInputs={set_errorInputs}
              set_student={set_student}
              sendAnswers={sendAnswers}
              student={student}
              set_step={set_step}
            />
          )}
          {step >= 2 &&
            step <= survey["questions"].length + 1 &&
            renderQuestions(step - 2)}
          {step === survey["questions"].length + 2 && (
            <div className="quoteSending">
              <p>Enviando...</p>
            </div>
          )}
          {step === survey["questions"].length + 3 && (
            <SurveyEndingScreen
              student={student}
              survey={survey}
              set_step={set_step}
              match={props.match}
              getArr={getArr}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SurveyQuestions;
