import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';

const QuestionLayout = (props) => {
  const {
    set_answers,
    answers,
    questions,
  } = props;

  const setQuestion = (answer) => {
    let newAnswers = { ...answers, [answer.num]: answer };
    set_answers(newAnswers);
  };

  let checkboxQuestion = (question, number) => (
    <Checkbox
      radio
      label={number}
      name={question.num}
      value={number}
      checked={
        answers[question.num]
          ? answers[question.num].grade === number
          : null
      }
      onChange={() =>
        setQuestion({
          letter: question.letter,
          grade: number,
          num: question.num,
        })
      }
    />
  );

  return (
    <Form>
      {questions.questions.map((question) => (
        <Form.Field key={question.num}>
          {question.sec && <h3>{question.sec}</h3>}
          <div className="question">
            <span className='text'><strong>{question.num}.</strong> {question.question}</span>
            <div className="answers">
              {!!question.answers && (question.answers).map(answer => (
                <div className="answer" key={answer.num}>
                  <span className="num">{answer.num}.</span> {answer.answer}
                </div>
              ))}
            </div>
          </div>
          <div className="options">
            {(questions.options).map((number) => checkboxQuestion(question, number))}
          </div>
        </Form.Field>
      ))}
    </Form>
  );
};

export default QuestionLayout;
