import proxybase from './proxybase';

class surveyService {
  getAllDISCs({ body, survey_name = '' }) {
    return proxybase
      .post(`/survey/getAnswers/${survey_name}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  DISCForm(body) {
    return proxybase
      .post(`/survey/new`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  DISCuserAnswer(body) {
    return proxybase
      .post(`/survey/userAnswers`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getSurveys({ body = {} }) {
    return proxybase
      .post(`/survey/AllSurveys`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
export default new surveyService();
