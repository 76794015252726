import React from "react";
import QuestionLayout from "../questionLayout";
import { Button } from "semantic-ui-react";
import {
  errorInput,
  uniqueElements2Arrays,
  scrollUp,
} from "../../../components/helpers";

const QuestionsLayout = (props) => {
  const {set_Answers, Answers, survey, index, set_step, errorInputs, set_errorInputs } = props;
  const [answersLeft, set_answersLeft] = React.useState([]);

  const verifyStepQuestions = ({ questions }) => {
    scrollUp();

    let answersLefts = uniqueElements2Arrays(
      questions.map((q) => q.num),
      Object.keys(Answers).map((e) => parseInt(e))
    );

    set_answersLeft(answersLefts);
    let errorInputs = {};

    errorInputs = errorInput({
      errorInputs,
      input: { name: "Answers", value: !answersLefts.length },
    });

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const questionsLeft = () => {
    return (
      <p style={{ color: "red" }}>
        Por favor llena la{answersLeft.length !== 1 && "s"} pregunta
        {answersLeft.length !== 1 && "s"}{" "}
        {answersLeft.map((answer) => (
          <span key={answer}>{answer}, </span>
        ))}{" "}
        para continuar.
      </p>
    );
  };

  const lastQuestionDisclaimer = (
    <p style={{ fontSize: "12px", lineHeight: "14px" }}>
      Ley de Protección de Datos Personales: Al enviar este formulario nos da
      autorización y nos faculta para que demos a sus datos aquí recopilados
      un tratamiento exclusivo para los propósitos de esta plataforma, y para
      uso de los facilitadores que lo refirieron a usted a esta. El titular de
      los datos podrá, en cualquier momento, solicitar que la información sea
      modificada, actualizada o retirada de nuestras bases de datos.
    </p>
  );
  const lastQuestion = survey["questions"].length === index + 1;

  return (
    <>
      {errorInputs["Answers"] && questionsLeft()}
      <QuestionLayout
        set_answers={set_Answers}
        answers={Answers}
        numberOptions={survey["grades"]}
        questions={survey["questions"][index]}
      />
      {lastQuestion && lastQuestionDisclaimer}
      <Button
        primary
        onClick={() =>
          verifyStepQuestions({
            questions: survey["questions"][index].questions,
          })
            ? set_step(index + 2)
            : set_step(index + 3)
        }
      >
        {lastQuestion ? `¡Enviar!` : `Continúa con la parte ${index + 2}`}
      </Button>
    </>
  );
};

export default QuestionsLayout;