import React from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import eventService from "../../services/events";
import authService from "../../services/auth";
import surveyService from "../../services/survey";
import EventModal from "./EventModal";
import { Input, Button, Select } from "semantic-ui-react";
import EventRow from "../../components/common/adminTable/eventRow";
import { chPerm } from "../../components/helpers";

const AllEvents = (props) => {
  const [allEvents, set_allEvents] = React.useState([]);
  const [allSurveys, set_allSurveys] = React.useState([]);
  const [loading, set_loading] = React.useState(true);
  const [seeAll, set_seeAll] = React.useState(null);
  const [create, set_create] = React.useState(false);
  const [inputs, setInputs] = React.useState({});
  const [endPage, set_endPage] = React.useState(false);
  const [currentPage, set_currentPage] = React.useState(1);
  const currentUser = authService.getCurrentUser();

  React.useEffect(() => {
    if (currentUser.profile === 1) {
      set_seeAll(true)
    } else {
      set_seeAll(false)
    }
  }, []);
  
  React.useEffect(() => {
    getSurveys()
  }, []);

  React.useEffect(() => {
    if (seeAll !== null) getInfo();
  }, [seeAll]);

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  React.useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  function getInfo(page = false) {
    set_loading(true);
    set_endPage(false);

    const {name, approved} = inputs

    let user_id = seeAll ? null : currentUser.id

    if (props.match.params.user_id && chPerm(['admin'], currentUser)) {
      user_id = props.match.params.user_id
    }

    let body = {
      name,
      currentPage,
      approved,
      user_id
    };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    eventService
      .getAllEvents({ body })
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          if (page) set_allEvents([...allEvents, ...response.data.events]);
          else set_allEvents(response.data.events);
          if (response.data.events.length === 0) {
            set_endPage(true);
          }
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(true);
      });
  }

  function getSurveys() {
    surveyService
      .getSurveys({})
      .then((response) => {
        if (response.data.success) {
          set_allSurveys(response.data.surveys);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
      });
  }

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      getInfo();
    }
  };

  const selectHandle = (a, { value, name }) =>
    handleInputs({ target: { value, name } });

  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Button onClick={() => props.history.goBack()}>Atrás</Button>
        {currentUser.profile === 1 && !props.match.params.user_id && (
          <Button primary onClick={() => set_seeAll(!seeAll)} >
            Ver {seeAll ? "solo tus" : "todos los"} eventos
          </Button>
        )}
        {create && <EventModal allSurveys={allSurveys} getInfo={getInfo} match={props.match} set_create={set_create} />}
        <Button primary onClick={() => set_create(true)}>
          Crear un evento
        </Button>
        <h2 style={{padding: '20px 0 0 0'}}>Todos {seeAll ? "los" : "tus"} eventos {props.match.params.user_id && "del usuario"}</h2>
        <div className="options">
          <div className="search-query">
            <p className="title">Buscar</p>
            <Input
              loading={loading}
              name={"name"}
              onChange={handleInputs}
              value={inputs.name}
              placeholder="Buscar por nombre"
              onKeyPress={onEnter}
            />
            {currentUser.profile === 1 && (
              <div style={{padding: '0 0 0 5px', display: 'inline-block' }}>
                <Select
                  placeholder="Filtrar por aprobado"
                  onChange={selectHandle}
                  value={inputs.approved}
                  name={'approved'}
                  options={
                    [{t: 'Aprobados', v: true}, {t: 'No Aprobados', v: false}].map(a => {
                    return { key: a.t, value: a.v, text: a.t };
                  })}
                />
              </div>
            )}
            <Button primary onClick={() => getInfo()}>
              Buscar
            </Button>
            <p style={{fontSize: "14px"}}>Puedes hacer clic en el nombre para editar</p>
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Participantes</th>
            <th>Aprobado</th>
            <th>Enlace Encuesta</th>
            {/*<th>Fecha</th>*/}
            <th>Encuestas</th>
          </tr>
        </thead>
        <tbody>
          {allEvents.map((event) => (
            <EventRow allSurveys={allSurveys} event={event} history={props.history} getInfo={getInfo} match={props.match} />
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: "center", margin: "20px 0 " }}>
        <Button loading={loading} disabled={endPage} onClick={() => loadMore()}>
          Cargar más
        </Button>
      </div>
    </section>
  );
};

export default AllEvents;
