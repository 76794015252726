import React from "react";
import ConditionalAnswers from "./ConditionalAnswers";
import surveyService from "../../services/survey";
import eventService from "../../services/events";
import { Button } from "semantic-ui-react";
import Swal from "sweetalert2/dist/sweetalert2";
import queryString from "query-string";
import Layout from "../layout/Layout";

const UserAnswersForm = (props) => {
  const [Answer, set_Answer] = React.useState({});
  const [inputs, setInputs] = React.useState({});
  const [loaded, set_loaded] = React.useState(null);
  const [loading, set_loading] = React.useState(true);

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  React.useEffect(() => {
    setInputs({ identification: parsedQueryString.identification });
  }, []);

  React.useEffect(() => {
    if (parsedQueryString.identification === inputs.identification)
      getInfo({ preventDefault: () => {} });
  }, [inputs.identification]);

  React.useEffect(() => {
    document.title = "Resultados | Coaching Teams";
    if (props.match.params.event_name) {
      set_loading(true);

      const body = {
        name: props.match.params.event_name,
        survey: props.match.params.surveyName,
      };

      eventService
        .getEvent(body)
        .then((response) => {
          if (response.data.success && response.data.event)
            setInputs({ ...inputs, event: response.data.event });
          if (!response.data.success && !response.data.approved) {
            if (response.data.message) Swal.fire(response.data.message);
          }
          set_loading(false);
        })
        .catch((error) => {
          Swal.fire("Hubo un error, por favor vuelve a intentar mas tarde.");
          set_loading(false);
        });
    }
  }, []);

  function getInfo(e) {
    e.preventDefault();
    const { identification } = inputs;
    const { event_name, surveyName } = props.match.params;

    if (!identification) return Swal.fire("Escribe tu cedula");

    surveyService
      .DISCuserAnswer({ identification, event_name, survey_name: surveyName })
      .then((response) => {
        if (response.data.success) {
          set_loaded(true);
          set_Answer(response.data.Answer);
        } else {
          Swal.fire(response.data.message);
          set_loaded(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(null);
      });
  }

  const handleLoginInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const answers = () => {
    if (!Answer.id) {
      return (
        <section className="AdminTable">
          {loaded === null ? (
            <form onSubmit={(e) => getInfo(e)} className="Registration__form">
              <label
                style={{ display: "block", padding: "20px", fontSize: "35px" }}
              >
                Escribe tu cedula
              </label>
              <input
                placeholder="Cedula"
                type="number"
                style={{ border: "1px solid black" }}
                name={"identification"}
                className="Registration__input Registration__input-id"
                value={inputs.identification}
                min={0}
                onChange={handleLoginInputs}
                style={{ width: "33%", margin: "5px" }}
              />
              <Button disabled={loaded === false} onClick={(e) => getInfo(e)}>
                Entrar
              </Button>
            </form>
          ) : (
            <p>Cargando</p>
          )}
        </section>
      );
    } else {
      return (
        <div className="AdminTable">
          <div className="AdminTable__content">
            <div style={{ padding: "20px 0" }}>
              <h1 style={{ textAlign: "center" }}>
                Test {props.match.params.surveyName.replace(/_/g, " ")}
              </h1>
              <ConditionalAnswers
                match={props.match}
                Answer={Answer}
                set_showAnswers={() => window.location.reload()}
                surveyName={props.match.params.surveyName}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Layout loading={loading} event={inputs.event ? inputs.event : {}}>
      {answers()}
    </Layout>
  );
};

export default UserAnswersForm;
