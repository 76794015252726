import React from 'react';
import { Link } from 'react-router-dom';
import authService from '../../services/auth';
//import queryString from 'query-string';
import { chPerm } from '../../components/helpers';
import UserConfigurations from './userConfiguration';
import { Button } from 'semantic-ui-react';

const RegisterModule = props => {
  const currentUser = authService.getCurrentUser();
/*  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);*/

  React.useEffect(() => {
    if (document) {
      try {
        document.title = 'Coaching Teams | Administrar ';
      } catch (error) {
        console.log(error);
      }
    }
  }, []);
  
  return (
    <>
      <section className="Home">
        <div className="Home__content">
          <h1 className="Home__title">Bienvenido <br/><small>{currentUser.name}</small></h1>
          <p className="Home__intro">
           Coaching Teams
          </p>
        </div>
        <div className="Home__buttons">
          
          {chPerm(['events'], currentUser) && (
            <Link to="/eventos">
              <Button primary size="tiny">Ver eventos</Button>
            </Link>
          )}
        </div>
        {currentUser.profile === 1 && (
          <Link to="/admin" className="Home__link">
            Administrar
          </Link>
        )}
        {currentUser.profile !== 1 && (
          <UserConfigurations />
        )}
      </section>
    </>
  );
};

export default RegisterModule;
