import React from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import authService from "../../services/auth";
import { Input, Button } from "semantic-ui-react";
import UserRow from "../../components/common/adminTable/userRow";
import UserModal from "./UserModal";

const AllUsers = (props) => {
  const [allUsers, set_allUsers] = React.useState([]);
  const [loading, set_loading] = React.useState(true);
  const [create, set_create] = React.useState(false);
  const [inputs, setInputs] = React.useState({});
  const [endPage, set_endPage] = React.useState(false);
  const [currentPage, set_currentPage] = React.useState(1);

  React.useEffect(() => {
    getInfo();
  }, []);

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  React.useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  function getInfo(page = false) {
    set_loading(true);
    set_endPage(false);

    const {keyword} = inputs

    let body = {
      keyword,
      currentPage,
    };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    authService
      .getAllUsers( body )
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          if (page) set_allUsers([...allUsers, ...response.data.allUsers]);
          else set_allUsers(response.data.allUsers);
          if (response.data.allUsers.length === 0) {
            set_endPage(true);
          }
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(true);
      });
  }

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      getInfo();
    }
  };

  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Button onClick={() => props.history.goBack()}>Atrás</Button>
        {create && <UserModal getInfo={getInfo} match={props.match} set_create={set_create} />}
        <Button primary onClick={() => set_create(true)}>
          Crear un usuario
        </Button>
        <h2 style={{padding: '20px 0 0 0'}}>Todos los coaches / usuarios</h2>
        <div className="options">
          <div className="search-query">
            <p className="title">Buscar</p>
            <Input
              loading={loading}
              name={"keyword"}
              onChange={handleInputs}
              value={inputs.keyword}
              placeholder="Buscar"
              onKeyPress={onEnter}
            />
            <Button primary onClick={() => getInfo()}>
              Buscar
            </Button>
            {/*<p style={{fontSize: "14px"}}>Puedes hacer clic en el nombre para editar</p>*/}
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Eventos</th>
          </tr>
        </thead>
        <tbody>
          {allUsers.map((user) => (
            <UserRow user={user} history={props.history} getInfo={getInfo} match={props.match} />
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: "center", margin: "20px 0 " }}>
        <Button loading={loading} disabled={endPage} onClick={() => loadMore()}>
          Cargar más
        </Button>
      </div>
    </section>
  );
};

export default AllUsers;
