import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import Home from '../containers/Home';
import Admin from '../containers/Admin';
import Error404 from '../containers/Error404';
import Login from '../containers/Login';
import '../assets/styles/App.scss';
import PrintPage from '../components/printQueue/printPage';
import Certificate from '../containers/Certificate';
import authService from '../services/auth';
import SurveyQuestions from '../containers/surveys/SurveyQuestions';
import DISCForm from '../containers/admin/AllAnswersTables';
import AllEvents from '../containers/events/AllEvents';
import UserAnswersForm from '../components/surveys/UserAnswersForm';
import AllUsers from '../containers/auth/AllUsers';

const App = () => {
  const loc = window.location;
  const protocol = process.env.REACT_APP_PROTOCOL || 'https'
  const hostname = process.env.REACT_APP_HOSTNAME || 'localhost'
  
  React.useEffect(() => {
    if (loc.hostname === hostname) {
      if (loc.protocol !== `${protocol}:`) {
        loc.replace(`${protocol}:${loc.href.substring(loc.protocol.length)}`);
      }
    }
    if (loc.hostname !== hostname) {
      loc.replace(`${protocol}://${hostname}${loc.pathname}${loc.search}`);
    }
  }, []);
  function Logout({ component: Component, ...rest }) {
    if (localStorage.getItem('userToken')) {
      return authService.logout();
    }
  }
  function PrivateRoute({ component: Component, ...rest }) {
    if (localStorage.getItem('userToken')) {
      return (
        <Route
          {...rest}
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      );
    } else {
      return window.location.replace('/');
    }
  }
  function RouteLayout({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
  if (
    ((loc.hostname === hostname && loc.protocol === `${protocol}:`))
  ) {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/checkin/print" component={PrintPage} />
          <Route exact path="/certificate" component={Certificate} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/encuesta/:surveyName/:event_id?" component={SurveyQuestions} />
          <Route
            exact
            path="/resultados/:surveyName/:event_name"
            component={UserAnswersForm}
          />
          <RouteLayout exact path="/" component={Home} />
          <RouteLayout exact path="/login" component={Login} />
          <PrivateRoute exact path="/admin" component={Admin} />
          <PrivateRoute exact path="/eventos/:user_id?" component={AllEvents} />
          <PrivateRoute exact path="/usuarios" component={AllUsers} />
          <PrivateRoute
            exact
            path="/encuestas/:event_name?"
            component={DISCForm}
          />
          <RouteLayout component={Error404} />
        </Switch>
      </BrowserRouter>
    );
  } else {
    return <p>Cargando una conexión segura</p>;
  }
};

export default App;
