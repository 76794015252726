export function addCounter() {
  // create a new div element
  const newDiv = document.createElement("div");

  newDiv.id = "timer";
  newDiv.className = "timer";

  // add the text node to the newly created div
  newDiv.innerHTML = `
      <div class="box">
          <h3 id="hourstxt">Hor.</h2>
          <h1 id="hours">00</h1>
      </div>
      <div class="box">
          <h3 id="minutestxt">Min.</h2>
          <h1 id="minutes">00</h1>
      </div>
      <div class="box">
          <h3 id="secondstxt">Seg.</h2>
          <h1 id="seconds">00</h1>
      </div>
    `;

  const currentDiv = document.getElementById("div1");
  document.body.insertBefore(newDiv, currentDiv);
}

export function setTimers(time) {
  const newscript = document.createElement("script");
  
  newscript.id = "timerScript";
  newscript.className = "timerScript";

  const newDate = new Date();
  newDate.setSeconds(newDate.getSeconds() + time);

  newscript.innerHTML = `
    var timer = () =>{
      const finaleDate = new Date(${newDate.getTime()}).getTime();
      const now = new Date().getTime();
      let diff = finaleDate - now;
      
      if(diff < 0){
          document.querySelector('.alert').style.display = 'block';
          document.querySelector('.container').style.display = 'none';
      }
      
      let hours = Math.floor(diff % (1000*60*60*24) / (1000*60*60));
      let minutes = Math.floor(diff % (1000*60*60)/ (1000*60));
      let seconds = Math.floor(diff % (1000*60) / 1000);
      
      hours <= 9 ? hours = '0'+ hours : hours;
      minutes <= 9 ? minutes = '0'+ minutes : minutes;
      seconds <= 9 ? seconds = '0'+ seconds : seconds;   
  
      document.querySelector('.timer .box #hours').textContent = hours;
      document.querySelector('.timer .box #minutes').textContent = minutes;
      document.querySelector('.timer .box #seconds').textContent = seconds;
    }
    var activeTimer = setInterval(timer,1000);
    `;
  const currentDiv = document.getElementById("div1");
  document.body.insertBefore(newscript, currentDiv);
}

export const clearTimers = () => {
  try {
    clearInterval(window.activeTimer);
    let timerDiv = document.getElementById('timer');
    if (timerDiv) timerDiv.remove();

    let timerScript = document.getElementById('timerScript');
    if (timerScript) timerScript.remove();

  } catch {}
}
