import React from "react";

const SurveyEndingScreen = (props) => {
  const { student, survey, set_step, getArr } = props;

  const surveyName = props.match.params.surveyName;
  const eventName = student.event.name;

  const inviteToFollowingSurveys = () => {
    const nextSurveyIndex = student.event.surveys.indexOf(surveyName) + 1;
    const nextSurveyName = student.event.surveys[nextSurveyIndex];

    const { identification, name, lastName, phone, email, birthdate, academicStatus } = student;

    if (nextSurveyName) {
      let linkNextSurvey = `https://${window.location.hostname}/encuesta/${nextSurveyName}/${eventName}?identification=${identification}&name=${name}&lastName=${lastName}&phone=${phone}&email=${email}&birthdate=${birthdate}&academicStatus=${academicStatus}`;
      survey.extraInputs.forEach((input) => {
        linkNextSurvey += `&${input.name}=${student[input.name]}`;
      });
      return [
        `Gracias por completar el segmento ${surveyName.replace(
          /_/g,
          " "
        )}, le invitamos a que continúe con el siguiente:`,
        `<a href="${linkNextSurvey}">${nextSurveyName.replace(
          /_/g,
          " "
        )} haciendo click aqui.</a>`,
      ];
    }
  };

  return (
    <>
      <div className="quoteSending">
        <h2>¡Enviado!</h2>
        {student.event && student.event.user_view ? (
          <p>
            <a
              href={`https://${window.location.hostname}/resultados/${surveyName}/${eventName}?identification=${student.identification}`}
            >
              Haz clic aqui para ver los resultados
            </a>
          </p>
        ) : (
          <p>
            ¡Gracias por llenar {survey.description && survey.description.kind}!
          </p>
        )}
        {[
          ...getArr(survey.description.endingDescriptions),
          ...getArr(inviteToFollowingSurveys()),
        ].map((endingDescriptions) => (
          <p
            dangerouslySetInnerHTML={{
              __html: endingDescriptions,
            }}
            style={{ textAlign: "center", margin: 0 }}
            key={endingDescriptions}
          />
        ))}
      </div>
      <span onClick={() => set_step(1)} style={{ display: "none" }}>
        .
      </span>
    </>
  );
};

export default SurveyEndingScreen;
