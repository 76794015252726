import React from "react";
import DISCUserAnswers from "./DISC/DISCUserAnswers";

const ConditionalAnswers = (props) => {
  const {match, Answer, set_showAnswers, surveyName} = props

  if (surveyName === "Estilo_de_Comunicacion")
    return (
      <DISCUserAnswers match={match} answer={Answer} set_showAnswers={set_showAnswers} />
    );
  return <p>Error, no se encuentra plantilla para mostrar los resultados de esta encuesta.</p>
};

export default ConditionalAnswers;
