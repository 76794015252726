import React from "react";
import { Button } from "semantic-ui-react";
import { clickableStyles } from "../../helpers";
import UserModal from "../../../containers/auth/UserModal";

const UserRow = (props) => {
  const { user, getInfo } = props;
  const [editing, set_editing] = React.useState(false);

  let { name, id, email } = user;

  return (
    <tr>
      {editing && (
        <UserModal
          getInfo={getInfo}
          match={props.match}
          set_create={set_editing}
          match={props.match}
          editingInputs={user}
        />
      )}
      <td>
        <p
          style={{ margin: "0", ...clickableStyles }}
          onClick={() => set_editing(true)}
        >
          {name}
        </p>
      </td>
      <td>
        <p style={{ margin: "0" }}>{email}</p>
      </td>
      <td>
        <Button
          onClick={() => props.history.push(`/eventos/${id}`)}
        >
          Ver
        </Button>
      </td>
    </tr>
  );
};

export default UserRow;
