import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Icon } from "semantic-ui-react";
import authService from "../services/auth";
import { chPerm } from "../components/helpers";
import queryString from "query-string";

const AdminPanel = (props) => {
  const currentUser = authService.getCurrentUser();
  const [currentFolder, set_currentFolder] = React.useState("");

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  React.useEffect(() => {
    props.history.push(`?folder=${currentFolder}`);
  }, [currentFolder]);

  React.useEffect(() => {
    set_currentFolder(parsedQueryString.folder);
  }, []);

  return (
    <section className="admin">
      <div>
        {chPerm(["encuestas", "admin"], currentUser) && (
          <Link to="/encuestas">
            <Button secondary>
              <Icon name="archive" /> Encuestas
            </Button>
          </Link>
        )}
        {chPerm(["admin"], currentUser) && (
          <Modal
            closeIcon
            onClose={() => set_currentFolder("")}
            open={currentFolder === "aplicacion"}
            trigger={
              <Button primary onClick={() => set_currentFolder("aplicacion")}>
                <Icon name="folder" /> Aplicación
              </Button>
            }
          >
            <Modal.Header>
              <Icon name="folder open" /> Aplicación
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                {chPerm(["admin"], currentUser) && (
                  <Link to="/usuarios">
                    <Button secondary>
                      <Icon name="archive" />  Coaches / Usuarios
                    </Button>
                  </Link>
                )}
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
      </div>
    </section>
  );
};

export default AdminPanel;
