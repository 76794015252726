import React from 'react';
import queryString from 'query-string';
import Barcode from 'react-barcode';
import printQueueService from '../../services/printQueue';

const PrintPage = props => {
  const [name, set_name] = React.useState('');
  const [identification, set_identification] = React.useState('');

  function updateAPrintQueue(identification) {
    printQueueService.updateAPrintQueue(identification);
  }
  React.useEffect(() => {
    let query = props.location.search;
    let parsed = queryString.parse(query);

    if (parsed.id) set_identification(parsed.id);
    if (parsed.name) set_name(parsed.name);

    setTimeout(() => {
      window.print();
    }, 500);

    setTimeout(() => {
      updateAPrintQueue(identification);
      window.close();
    }, 2000);
  }, []);

  return (
    <section className="print">
      <p>{name}</p>
      <div className="barcode">
        <Barcode value={identification} />
      </div>
    </section>
  );
};

export default PrintPage;
